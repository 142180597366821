import React, { useState, createContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

export const ScrollContext = createContext(null)

const Layout = ({ children }) => {
  const [firstBlockVisibility, setFirstBlockVisibility] = useState(true)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ScrollContext.Provider
      value={[firstBlockVisibility, setFirstBlockVisibility]}
    >
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer>
          <div>
            © {new Date().getFullYear()} Village Media. Minden jog fenntartva.
          </div>
        </footer>
      </div>
    </ScrollContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
