import React from "react"

import "./relations.scss"

const Relations = ({ section }) => {
  return (
    <section id="relations">
      <div>
        <h3>{section.title}</h3>
        <div className="collegues-container">
          {section.blocks.map((b, i) => (
            <div className="collegue" key={i}>
              <h4>{b.title}</h4>
              {b.content.split(",").map((item, index) => {
                if (!index) {
                  return <div key={index}>{item}</div>
                } else if (index === 1) {
                  return (
                    <a key={index} href={`tel:${item.replace(/ /g, "")}`}>
                      {item}
                    </a>
                  )
                } else {
                  return (
                    <a key={index} href={`mailto:${item}`}>
                      {item}
                    </a>
                  )
                }
              })}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Relations
