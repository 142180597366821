import React from "react"

import Image from "../image"

import "./contact.scss"

import Logo from "../../images/logo.svg"
import pdf from '../../files/VillageMedia_Prezentacio.pdf'

const Contact = ({ section, hidePicture, mainSection }) => {
  const image = {
    fileName: "team_photo.png",
    alt: "Csapatfoto",
    style: { minWidth: "670px", order: 0 },
  }
  return (
    <section
      id="contact"
      className={
        hidePicture ? "hidden-picture" : mainSection ? "main-section" : ""
      }
    >
      <div style={{
        gap: '30px'
      }}>
        <div>
          <h3>{section.title}</h3>
          <span>
            <a href={pdf} target="_blank" style={{
              textTransform: 'uppercase',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px',
              fontWeight: 700,
              marginTop: '10px',
              display: 'block',
              lineHeight: 1.5
            }}>Bemutatkozó prezentáció letöltése&nbsp;{">"}</a>
          </span>
        </div>
        <div className="contact-details">
          {section.blocks.map((b, i) => (
            <div key={i}>
              <Logo />
              <h4>{b.title}</h4>
              {b.content.split(";").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          ))}
        </div>
        {!hidePicture && <Image {...image} />}
      </div>
    </section>
  )
}

export default Contact