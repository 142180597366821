import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm';
import { Link } from "gatsby"

import Layout from "../components/layout"
import Contact from "../components/common/contact"
import Relations from "../components/common/relations"

import "./news.scss"

import Arrow from "../images/arrow.svg"

const News = ({ pageContext }) => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        contact {
          title
          sections {
            title
            key
            blocks {
              title
              content
            }
          }
        }
      }
    }
  `)
  const { sections } = pages.contact
  const { news } = pageContext
  const date = new Date(news.published_at)
  const imgUrl =
    news.thumbnail.formats && news.thumbnail.formats.large
      ? news.thumbnail.formats.large.url
      : news.thumbnail.url

  return (
    <Layout>
      <section className="news-full-content">
        <div>
          <div className="left-column">Hírek</div>
          <div className="right-column">
            <div className="back-navigation">
              <Link to="/news">
                <Arrow />
                <div>Vissza</div>
              </Link>
            </div>
            <img src={`https://www.villagemedia.hu/api${imgUrl}`} alt="cover" />
            <h2>{news.title}</h2>
            <div className="date">{`${date.getFullYear()} - ${
              date.getMonth() + 1
            } - ${date.getDate()}`}</div>
            <div className="news-text">
              <ReactMarkdown children={news.content} remarkPlugins={[remarkGfm]} />
            </div>
          </div>
        </div>
      </section>
      <Contact
        section={sections.find(s => s.key === "contact")}
        hidePicture={true}
      />
      <Relations section={sections.find(s => s.key === "relations")} />
    </Layout>
  )
}

export default News
