import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

const Links = ({ close }) => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        homepage {
          components {
            posts {
              id
              title
              content
              description
              published_at
              thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { posts } = pages.homepage.components
  return (
    <>
      <Link onClick={close} to="/">
        Főoldal
      </Link>
      <Link onClick={close} to="/about-us/">
        Rólunk
      </Link>
      <Link onClick={close} to="/portfolio/">
        Portfóliónk
      </Link>
      {posts.length >= 2 ? (
        <Link onClick={close} to="/news/">
          Hírek
        </Link>
      ) : null}
      <Link onClick={close} to="/contact/">
        Kapcsolat
      </Link>
    </>
  )
}

export default Links
