import React from "react"
import Links from "./links"

import "./menu.scss"

const Menu = ({ open, close }) => {
  return (
    <div id="menu" className={open ? "open" : ""}>
      <Links close={close} />
      <div>© {new Date().getFullYear()} Village Media</div>
    </div>
  )
}

export default Menu
