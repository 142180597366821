import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import Menu from "../components/header/menu"
import Links from "../components/header/links"

import { useWindowWidth } from "../services/hooks"

import "./header.scss"
import variables from "./variables.scss"

import { ScrollContext } from "./layout"

import LogoWithText from "../images/logo_with_text.svg"
import Logo from "../images/logo.svg"

const Header = () => {
  const [open, setOpen] = useState(false)
  const width = useWindowWidth()
  const [isFirstBlockVisible] = useContext(ScrollContext)
  const isMobile = width <= parseInt(variables.mobile)

  return (
    <header
      className={`${isMobile && !isFirstBlockVisible ? "white-bg" : ""} ${
        !isFirstBlockVisible ? "no-padding" : ""
      }`}
    >
      <div>
        <div className="logo-container">
          <Link onClick={() => setOpen(false)} to="/">
            {isFirstBlockVisible ? <LogoWithText /> : <Logo />}
          </Link>
        </div>
        <div className="link-container">
          <Links />
        </div>
        <button
          className={"menu-btn" + (open ? " close" : "")}
          onClick={() => setOpen(value => !value)}
        >
          <div className="btn-line"></div>
          <div className="btn-line"></div>
          <div className="btn-line"></div>
        </button>
        <Menu open={open} close={() => setOpen(false)} />
      </div>
    </header>
  )
}

export default Header
